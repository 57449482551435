import React from "react"

import { useIntl } from "react-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ExchangeNavigation from "../components/exchange_navigation"
import ExchangeOrderHistory from "../components/exchange_order_history"

const ExchangeOrderHistoryPage = ({ location }) => {
  const intl = useIntl()
  const pageTitle = intl.formatMessage({ id: "Order History" })
  return (
    <Layout pageTitle={pageTitle} location={location}>
      <SEO title={pageTitle} />
      <ExchangeNavigation location={location} />
      <ExchangeOrderHistory />
    </Layout>
  )
}

export default ExchangeOrderHistoryPage

import React, { Component, Fragment } from "react"

import { withAuth0 } from "@auth0/auth0-react"
import FingerprintJS from "@fingerprintjs/fingerprintjs"
import Alert from "react-bootstrap/Alert"
import Button from "react-bootstrap/Button"
import Card from "react-bootstrap/Card"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { FormattedMessage } from "react-intl"
import { injectIntl } from "react-intl"
import ReactMarkdown from "react-markdown"

import ExchangeOrderDetails2 from "./exchange_order_details_2"
import Space from "./space"

class ExchangeOrderHistory extends Component {
  constructor(props) {
    super(props)
    this.handleClearAll = this.handleClearAll.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSearch = this.handleSearch.bind(this)

    const { user } = this.props.auth0

    this.state = {
      status: "search",
      alert: null,
      email: user != null ? user.email : "",
      orderId: "",
    }
  }

  componentWillReceiveProps(nextProps) {
    const nextUser = nextProps.auth0.user
    const { user } = this.props.auth0
    if (nextUser != user) {
      if (nextUser.email != null) {
        this.setState({ email: nextUser.email })
      }
    }
  }

  handleClearAll(e) {
    e.preventDefault()

    const { user } = this.props.auth0

    this.setState({
      status: "search",
      alert: null,
      email: user != null ? user.email : "",
      orderId: "",
    })
  }

  handleInputChange(e) {
    const target = e.target
    const name = target.name
    const value = target.type === "checkbox" ? target.checked : target.value

    this.setState({ [name]: value })
  }

  handleSearch(e) {
    e.preventDefault()
    this.setState(
      {
        status: "searching",
        alert: null,
      },
      () => {
        FingerprintJS.load().then(fpAgent => {
          // new FingerprintJS().get(fingerprint => {
          fpAgent.get().then(fpResult => {
            const url = new URL("/exchange/get", process.env.GATSBY_API_URL)
            const params = {}
            params.email = this.state.email
            params.orderId = this.state.orderId
            params.fingerprint = fpResult.visitorId
            // params.fingerprint = fingerprint
            Object.keys(params).forEach(key =>
              url.searchParams.append(key, params[key])
            )
            fetch(url, {
              method: "GET",
            })
              .then(response => {
                if (response.ok) {
                  response.json().then(json => {
                    this.setState({
                      status: "details",
                      order: json,
                    })
                  })
                } else {
                  response.text().then(text => {
                    this.setState({
                      status: "search",
                      alert: text,
                    })
                  })
                }
              })
              .catch(error => {
                this.setState({
                  status: "search",
                  alert: error.message,
                })
              })
          })
        })
      }
    )
  }

  render() {
    const intl = this.props.intl
    return (
      <Row>
        <Col className="col-auto body1">
          <Card className="border-dark mb-2">
            <Card.Header className="text-white bg-dark">
              <FormattedMessage id={"Order History"} />
            </Card.Header>
            <Card.Body>
              {this.state.alert && (
                <Alert variant="danger">
                  <FormattedMessage id={this.state.alert} />
                </Alert>
              )}
              <Form autoComplete="off">
                {(this.state.status == "search" ||
                  this.state.status == "searching") && (
                  <>
                    <Form.Group>
                      <Form.Label>
                        <FormattedMessage id={"Email"} /> *
                      </Form.Label>
                      <Form.Control
                        name="email"
                        type="email"
                        value={this.state.email}
                        disabled={
                          this.state.status != "search" ||
                          this.props.auth0.user != null
                        }
                        onChange={this.handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>
                        <FormattedMessage id={"Order ID"} /> *
                      </Form.Label>
                      <Form.Control
                        name="orderId"
                        type="text"
                        value={this.state.orderId}
                        disabled={this.state.status != "search"}
                        onChange={this.handleInputChange}
                      />
                      <Form.Text className="text-muted">
                        <FormattedMessage
                          id={"Format example: ABCD-EFGH-IJKL-MNOP."}
                        />
                      </Form.Text>
                    </Form.Group>
                  </>
                )}
                {this.state.status == "details" && (
                  <ExchangeOrderDetails2 order={this.state.order} />
                )}
                {this.state.status == "search" && (
                  <>
                    <Button variant="primary" onClick={this.handleSearch}>
                      <FormattedMessage id={"Search"} />
                    </Button>
                    <Space />
                    <Button variant="secondary" onClick={this.handleClearAll}>
                      <FormattedMessage id={"Clear All"} />
                    </Button>
                  </>
                )}
                {this.state.status == "searching" && (
                  <>
                    <Button variant="primary" disabled>
                      <FormattedMessage id={"Search"} />
                    </Button>
                    <Space />
                    <Button variant="secondary" disabled>
                      <FormattedMessage id={"Clear All"} />
                    </Button>
                  </>
                )}
                {this.state.status == "details" && (
                  <>
                    <Button variant="primary" disabled>
                      <FormattedMessage id={"Search"} />
                    </Button>
                    <Space />
                    <Button variant="secondary" onClick={this.handleClearAll}>
                      <FormattedMessage id={"Clear All"} />
                    </Button>
                  </>
                )}
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-auto body2">
          <Alert variant="secondary">
            <ReactMarkdown
              children={intl.formatMessage({ id: "Exchange.Order History" })}
            />
          </Alert>
        </Col>
      </Row>
    )
  }
}

export default withAuth0(injectIntl(ExchangeOrderHistory))
